import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image'
const InformationHub = ({ queryData }) => {
    return (
        <div className='informatioHub'>
            <div className='informatioHub-grid'>
                <div>
                    <Link to='/NEWS'><h1>News</h1></Link>
                    <p>Get to know what’s going on for us.</p>
                </div>
                {/* <div>
                    <Link to='/Blogs/?cat=all'><h1>Blogs</h1></Link>
                    <p>Our experts are keen to produce knowledge<br></br> which can help others.</p>
                </div> */}
                {/* <div>
                    <Link to='/WebinarEvents'><h1>Webinar & Events</h1></Link>
                    <p>We provide opportunities to learn, not only within<br></br> but outside the Visiomate. </p>
                </div> */}
                <div>
                    <Link to='/WhitepapereBooks'><h1>Downloads</h1></Link>
                    <p>We produce documents which can provide insight about current technologies. </p>
                </div>

            </div>
            <div className='devupcom'>
                <h1 >Upcoming Event</h1>
                <div className='divSize'>
                    <Img fluid={queryData.learningcircle.childImageSharp.fluid} alt="learningcircle" />
                    <p>Feb 2024 - Learning circle, an internal event for employees to enhance there knowledge & skills.</p>
                </div>
            </div>
        </div >
    )
}

export default InformationHub
