import React, { memo, useState } from 'react'

import InfoModal from "./InfoModal"

const Talktous = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }


  return (
    <div className='talkToUs'>
      <h1>Begin your journey towards growth, take the first step</h1>
      <button onClick={showModal}>Tell us about your project</button>
      <InfoModal
        companyIsEnabled
        url="http://185.213.27.237:8066/Email/ContactUs"
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default memo(Talktous)
