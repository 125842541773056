import React from "react"
import arrow from "../images/blackarrow.png"

import { Link } from "gatsby"

const KnowUs = () => {
    return (
        <>
            <div className="Know-us">
                <div>
                    <Link to="/about">
                        <h1>
                            About us <img src={arrow} alt="About us"/>
                        </h1>
                    </Link>

                    <p>
                        Partnering with visionary businesses since 2018, we develop and
                        expand, always with customer satisfaction in mind.
                    </p>
                </div>
                <div>
                    <Link to="/lifeAtVisiomate">
                        <h1>
                            Life at Visiomate <img src={arrow} alt="Life at Visiomate"/>
                        </h1>
                    </Link>
                    <p>
                        We strive to cultivate a workplace that values positivity, fosters a
                        sense of community, and champions growth opportunities for
                        our employees.{" "}
                    </p>
                </div>
                <div>
                    <Link to="/career">
                        <h1>
                            Careers <img src={arrow} alt="Career"/>
                        </h1>
                    </Link>
                    <p>
                        As we continue to grow as an organization, we welcome new members to
                        our team with open arms and look forward to the endless
                        possibilities that lie ahead.
                    </p>
                </div>
            </div>
        </>
    )
}

export default KnowUs
