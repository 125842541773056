const services = [
  {
    title: "Web Application Development",
    description:
      "We take pride in crafting digital solutions that seamlessly align with your objectives, whether you require a dynamic e-commerce platform, a data-driven analytics tool, or a user-friendly customer portal. Our experienced team of developers, designers, and project managers works collaboratively to ensure that the end product not only meets your expectations but also exceeds them. We prioritize user experience, security, and scalability in every stage of the development process, from initial concept to deployment.",
    to: "/services/webdevelopment/",
  },
  {
    title: "Mobile Application Development",
    description: "Our mobile application development services are designed to bring your unique app vision to life and cater to the ever-growing demand for innovative and user-friendly mobile experiences. Our team of skilled developers and designers is committed to turning your ideas into fully functional, visually engaging, and intuitive mobile applications. Whether you're looking to create an iOS, Android, or cross-platform app, we follow a comprehensive development process that includes ideation, design, coding, rigorous testing, and deployment.",
    to: "/services/mobiledevelopment/",
  },
  {
    title: "Dynamics 365 & Odoo ERP ",
    description:
      "With Dynamics 365, you gain access to a powerful suite of integrated business applications, encompassing everything from customer relationship management (CRM) to enterprise resource planning (ERP) and more. The modular nature of Odoo ERP allows us to tailor a solution that perfectly aligns with your business requirements. Our expert team specializes in seamlessly integrating Dynamics 365 & Odoo with any third party solution, we have some  products of our own which are integrated with Dynamics 365 and Odoo.",
    to: "/services/erpdevelopment/",
  },
  {
    title: "Power BI & Power Apps",
    description:
      "Our Power BI and Power Apps services are the driving force behind data-driven innovation in your organization. Power BI allows you to uncover hidden insights from your data, turning raw information into actionable reports and interactive dashboards. It's the key to understanding trends, making informed decisions, and optimizing your business processes. Complementing this, Power Apps provides the agility to create customized applications without the need for extensive coding.",
    to: "/services/businessanalysis/",
  },
]

const projects = [
  {
    title: "Education Management System",
    type: 'product',
    category: 'EDUCATION',
    platform: 'Web Solution',
    FechorService: 'Admin, Teacher, Student, and Parent Portal, Craete & Publish Class, One Click Class Schedule, etc.',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "It covers all educational setups providing an effective and efficient way of managing classes. Provides the ability to manage programs, sessions, classes, admissions, enrolments, teachers, students, conduct classes, etc.",
    link: "/caseStudies/products/classManagement",
  },
  {
    title: "Distribution with D365",
    type: 'product',
    category: 'DISTRIBUTION',
    platform: 'Windows Application',
    FechorService: 'Order, Quantity, Price Integration between D365 and Xtack',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'MicrosoftDynamics'],
    description:
      "The distribution utility is integrated with Microsoft Dynamics D365. The purpose of this utility is to provide users ability to create transfer order in bulk from outside the Microsoft Dynamics D365.",
    link: "/caseStudies/products/mD365BulkStockDistribution",
  },
  {
    title: "VIZTOS POS",
    type: 'product',
    category: 'RETAIL',
    platform: 'Web Solution, Odoo Integration',
    FechorService: 'Offline Ordering, Fully personlizable product cards, Specially made for Cafe and lubricant Station, KDS, Multi-user Session, etc.',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "A POS system that provides the capability to perform retail sales from multiple platforms (Windows, Mac OS, Android tab). It comes with complete ERP at the back where customers can efficiently manage their purchases, inventory, accounts, payroll, etc.",
    link: "/caseStudies/products/viztos",
  },
  {
    title: "Visioedge A Complete Solution",
    type: 'Product',
    category: 'ERP Solution',
    platform: 'Odoo based Solution',
    FechorService: 'Strong Suply Chain, Specialized Feature for Restaurants, Robust internal or External Communication Workflows, etc.',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "One product covers most of the buniess opperation needs for small to med level orginizations. ",
    link: "/caseStudies/products/visioedge",
  },
  {
    title: "Umrah Shuttle App",
    type: 'product',
    category: 'Travel',
    platform: 'Mobile Application',
    FechorService: 'Umrah shuttle booking, Tracking custoner and driver location',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "This application bridge the travel agents and Umrah visitors, Umrah shuttle booking, Tracking custoner and driver's location, etc.",
    link: "",
  },
  {
    title: "Big Jerry's Fencing App",
    type: 'Project',
    category: 'Field Service',
    platform: 'Mobile Application',
    FechorService: 'Assigned jobs, process a job, upload evidance and checklists',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "Fencing installation staff Application, Installer can check assigned jobs, process a job, upload evidance and checklists regarding the job complition.",
    link: "",
  },
  {
    title: "SNGPL Mobile Application",
    type: 'project',
    category: 'CUSTOMER SERVICE',
    platform: 'Mobile Application',
    FechorService: 'GAS Connection Application, Download Bill, Pay GAS bill, Bill History',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "We developed a mobile application for SNGPL to get new connection requests from users.",
    link: "",
  },
  {
    title: "State Bank of Pakistan Mobile Application",
    type: 'project',
    category: 'CUSTOMER SERVICE',
    platform: 'Mobile Application',
    FechorService: 'Customer Complaint Creation, Complaint processing updates',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "We developed a mobile application for State Bank of Pakistan to get complaints regarding banks in pakistan from users.",
    link: "",
  },
  {
    title: "ORR Protection USA Custom Solution Development",
    type: 'project',
    category: 'DEVELOPMENT',
    platform: 'Team Extention',
    FechorService: '.Net based Development Services, Integration with different 3rd-party Applications',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "We help ORR Protection build their in-house software solution to support their day-to-day operations.",
    link: "/caseStudies/projects/ORR.js",
  },
  {
    title: "ORR Protection Odoo Support",
    type: 'project',
    category: 'DEVELOPMENT',
    platform: 'Team Extention',
    FechorService: 'Odoo Development Services, Integration with different 3rd-party Applications',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "We help ORR Protection to customize and maintain their Odoo solution and its integration with other systems.",
    link: "/caseStudies/projects/ORR.js",
  },
  {
    title: "Firhaj Footwear (Pvt.) Ltd ERP Support",
    type: 'project',
    category: 'ERP',
    platform: 'Team Extention',
    FechorService: 'Microsoft Dynamics 365 Support',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "We support Firhaj Footwear to customize and maintain its Dynamics AX 2012 ERP and its integration with other systems.",
    link: "",
  },
  {
    title: "Banu Mukhtar Odoo CRM Solution",
    type: 'project',
    category: 'ERP',
    platform: 'Team Extention',
    FechorService: 'Odoo CRM Implementation',
    technologies: ['react', 'javascript'],
    description:
      "We completed Odoo based CRM solution implementation with some customization to best map their operations.",
    link: "/caseStudies/projects/banuMukhtarCRM.js",
  },
  {
    title: "MIA Group Companies ERP Implementation and Support",
    type: 'project',
    category: 'ERP',
    platform: 'Team Extention',
    FechorService: 'Microsoft Dynamics 2012 Support',
    technologies: ['react', 'javascript'],
    description:
      "We performed a fit-gap analysis for their different business units and a complete re-implementation of Dynamics AX 2012 on one of the Cake & Bakes business units.",
    link: "/caseStudies/projects/miaGroup.js",
  },
  {
    title: "Cake & Bakes ERP Implementation and Support",
    type: 'project',
    category: 'ERP',
    platform: 'Team Extention',
    FechorService: 'Microsoft Dynamics 2012 Re-Implementation',
    technologies: ['react', 'javascript'],
    description:
      "We performed a complete fit-gap analysis, plus proposed and implemented the enhanced ERP solution to support their business process.",
    link: "/caseStudies/projects/cakesBakesDAX2012Revamp.js",
  },
  {
    title: "Averti Infotech Delivery schedule and dropoff Mobile App",
    type: 'project',
    category: 'LOGISTICS',
    platform: 'Mobile Application',
    FechorService: 'Mobile Application Developement, Integration with Odoo',
    technologies: ['react', 'javascript'],
    description:
      "This is a Delivery App which helps vehicle drivers to manage there delivery. Features: Vehicle drivers can check in starting inventory, Vehicle drivers can the assigned deliveries, Vehicle drivers can close the daily orders.",
    link: "",
  },
  {
    title: "Aamaal Group of Companies Odoo Implementation and support",
    type: 'project',
    category: 'ERP',
    platform: 'Team Extention',
    FechorService: 'Odoo Implementation and Support',
    technologies: ['react', 'javascript'],
    description: "Odoo implementation for there day to day processes.",
    link: "",
  },
  {
    title: "Muscat Media Group Odoo HR Solution",
    type: 'project',
    category: 'ERP',
    platform: 'ERP Solution',
    FechorService: 'Odoo Implementation and Support',
    technologies: ['react', 'javascript'],
    description:
      "Odoo implementation for there day to day processes. Modules: HR, Payroll, Inventory, Sales etc.",
    link: "/caseStudies/projects/muscatMGHSolution.js",
  },
  {
    title: "SOHAR Port and Freezone Container management based on Odoo",
    type: 'project',
    category: 'ERP',
    platform: 'ERP Solution',
    FechorService: 'Odoo Implementation and Support',
    technologies: ['react', 'javascript'],
    description:
      "Developed a 3rd party application which was integrated with Odoo to record there container ins and outs.",
    link: "/caseStudies/projects/sOHARPortandFCPortal.js",
  },
  {
    title: "United Alzahra Co LLC Odoo upgradation from 10 to 13",
    type: 'project',
    category: 'ERP',
    platform: 'ERP Solution',
    FechorService: 'Odoo Upgrade and Support',
    technologies: ['react', 'javascript'],
    description:
      "We helpped them Odoo upgrade from version 10 to 13 with all there customizations included.",
    link: "",
  },
  {
    title: "ZiePie Azure Services Support",
    type: 'project',
    category: 'CLOUD',
    platform: 'Team Extention',
    FechorService: 'Azure Cloud Management Services',
    technologies: ['react', 'javascript'],
    description:
      "We helpped them create data factories to migrate the data from one database to another.",
    link: "/portfolio/projects/ziePieAzureServiceSupport",
  },
  {
    title: "ZiePie PowerBI development",
    type: 'project',
    category: 'Power BI',
    platform: 'Team Extention',
    FechorService: 'Power BI Dashboards Development',
    technologies: ['react', 'javascript'],
    description:
      "We helpped them create many dashboard to visualize there data in the meaningful form.",
    link: "",
  },
  {
    title: "Farm/Poultry Management System",
    type: 'product',
    category: 'FARMING',
    platform: 'Web Solution',
    FechorService: 'Farm Management, Supply Chain Management',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "It is the perfect software for any cattle/poultry farm to run its operations. It provides features that can help manage your livestock, feed, medicine, and daily operations.",
    link: "",
  },
  {
    title: "Tender Asset Record Management",
    type: 'product',
    category: 'ASSET',
    platform: 'Web Solution',
    FechorService: 'Complete Tender processing documentation starage',
    technologies: ['html', 'css', 'sass', 'bootstrap', 'js', 'reactjs'],
    description:
      "It supports the tender processing by maintaining records for every step performed from budget approval to tender issuance to asset management.",
    link: "",
  },
]

const technologies = [
  "Frontend Web Development",
  "Backend Development",
  "Mobile App Development",
  "Databases",
  "Infra & DevOps",
  "ERP Solutions",
]
const events = [
  {
    id: 0,
    heading: "COMING SOON! a robust bootcampe for anyone who want's to be a successfull Mobile Developer",
    dataTime: '12th jan, 2024',
    description:
      "Header event details",
    location: 'Onile Session',
    button: 'Register'
  },
  {
    id: 1,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Register'
  },
  {
    id: 2,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Register'
  },
  {
    id: 3,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Register'
  },
  {
    id: 4,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Watch Recording'
  },
  {
    id: 5,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Watch Recording'
  },
  {
    id: 6,
    heading: "We employ a well-established agile methodology to ensure the swift",
    description:
      "We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift We employ a well-established agile methodology to ensure the swift",
    dataTime: '12th jan, 2024',
    location: 'Onile Session',
    button: 'Watch Recording'
  },
]
const emails = {
  info: "info@visiomate.com",
  hr: "hr@visiomate.com",
}

export { services, projects, technologies, emails, events }
