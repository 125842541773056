import React from 'react'
import Img from "gatsby-image"
const MainContent = ({ data, queryData }) => {
    return (
        < >
            <div className='mainMenuDiv'>
                <h1>What’s Latest</h1>
                <div className="main-div">
                        <div>
                            <Img fluid={queryData.umrahshuttleapp.childImageSharp.fluid} alt="umrahshuttleapp" />
                            <div className='p-div'><p>Our latest mobile application project going live soon, it include umrah shuttle booking, tracking custoner and driver's location, etc.</p></div>
                        </div>
                        
                        <div>
                            <Img fluid={queryData.UMTVisiomate.childImageSharp.fluid} alt="UMTVisiomate" />
                            <div className='p-div'><p>We take pride in crafting digital solutions that seamlessly align with your objectives.</p></div>
                        </div>
                        
                </div >
            </div >
        </>
    )
}

export default MainContent
