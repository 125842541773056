import React, { useState } from "react"
import {
  Typography,
  Modal,
  Input,
  Upload,
  Button,
  Form,
  message,
  Spin,
} from "antd"
import ReCAPTCHA from "react-google-recaptcha"
import { UploadOutlined } from "@ant-design/icons"
import "../styles/infoModal.scss"
import { getBase64 } from "./../utils/utils"
import { emails } from "../data"
const axios = require("axios")

const InfoModal = ({
  url,
  companyIsEnabled,
  isModalVisible,
  handleOk,
  handleCancel,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const [isHovered, setIsHovered] = useState(false)

  let loadSpin = false
  const loadingSpinner = () => {
    loadSpin = true
    return ""
  }
  const handleHover = () => {
    setIsHovered(!isHovered)
  }

  const { Title } = Typography
  const { TextArea } = Input

  const onCaptchaChange = value => {
    setCaptchaValue(value)
  }

  const normFile = e => {
    if (!fileList?.length) {
      return []
    }
    return Array.isArray(e) ? e : e && e.fileList
  }

  const handleFormData = async values => {
    const { name, email, phone, message, company, file } = values
    let attachment_base64 = null

    if (file && file[0] && file[0].originFileObj) {
      attachment_base64 = await getBase64(file[0].originFileObj)
    }
    const formData = {
      name: "Project Discussion: " + name,
      partner_name: company,
      contact_name: name,
      email_from: email,
      phone,
      mobile: phone,
      description: message,
      attachment_base64,
      email_to: emails.info,
    }

    sendFormData(formData, setLoading, url)
  }
  const sendFormData = async data => {
    try {
      setLoading(true)
      const response = await axios.post(
        "https://staging.visiomate.com/crmPipeline",
        data
      )
      setLoading(false)
      form.resetFields()
      handleOk()
      message.success("Application submitted successfully!")
    } catch (error) {
      console.error("Error:", error)
      message.error("Failed to submit application. Please try again later.")
    } finally {
      setLoading(false)
    }
  }
  const onFinish = values => {
    handleFormData(values)
  }

  const uploadProps = {
    fileList,
    accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx,",
    maxCount: 1,
    onRemove: file => {
      setFileList([])
    },
    beforeUpload: file => {
      const isLt200Kb = file.size / 1024 < 200
      if (!isLt200Kb) {
        message.error("File must be smaller than 200kb!")
        setFileList([])
        return false
      } else {
        setFileList([file])
      }
      return false
    },
  }

  return (
    <Modal
      centered
      width="80vw"
      visible={isModalVisible}
      onOk={handleOk}
      className="infoModal"
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
    >
      {companyIsEnabled && <Title level={3} style={{marginBottom:'1rem'}}>Tell us about your project</Title>}
      {!companyIsEnabled && <Title level={3} style={{marginBottom:'1rem'}}>Join Us</Title>}

      <Form
        form={form}
        name="about"
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label=""
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label=""
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Email" type="email" />
        </Form.Item>
        <Form.Item
          label=""
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your Phone!",
            },
            {
              pattern: /^\+92\d{3}\d{7}$/,
              message:
                'Please enter a valid phone number in the format "+923000000000"',
            },
          ]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        {!companyIsEnabled && (
          <Form.Item
            label=""
            name="designation"
            rules={[
              {
                required: true,
                message: "Please input Designation!",
              },
            ]}
          >
            <Input placeholder="Apply For Designation" />
          </Form.Item>
        )}
        {companyIsEnabled && (
          <Form.Item
            label=""
            name="company"
            rules={[
              {
                required: true,
                message: "Please input Your Company Name!",
              },
            ]}
          >
            <Input placeholder="Company" />
          </Form.Item>
        )}
        <Form.Item label="" name="message" rules="">
          <TextArea
            placeholder="Message"
            maxLength={100}
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
          <p style={{float:"right", marginBottom:"0"}}>100 Characters</p>
        </Form.Item>
        <Form.Item
          label=""
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: false,
              message: "Please upload File!",
            },
          ]}
          style={{marginBottom:"0"}}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>
              {companyIsEnabled
                ? "Upload Company Profile / Project File"
                : "Upload Your Resume"}
            </Button>
          </Upload>
        </Form.Item>
        <span className="file-limit-info">Max. size 200KB</span>
        <div className="g-recaptcha ">
        <ReCAPTCHA
          sitekey="6Le4Xc4pAAAAACdoP1-v8vNKtHOYfkJiXrOBvi8V"
          onChange={onCaptchaChange}
          style={{marginTop:"1rem"}}
        />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span className="submit" style={{ width: 120, marginRight: 15 }}>
            <a
              href="#"
              disabled={captchaValue === null}
              onClick={e => {
                e.preventDefault()
                if (captchaValue === null) {
                  return
                }
                form.submit()
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              data-front={
                loading ? loadingSpinner() : isHovered ? "Submit" : "Submit"
              }
              data-back={
                loading ? loadingSpinner() : isHovered ? "Submit" : "Submit"
              }
            />
            {loadSpin ? <Spin className="spinner-for-proposal-form" /> : ""}
          </span>
        </div>
      </Form>
    </Modal>
  )
}

export default InfoModal
