import React, { memo, useState,useEffect } from "react"
import { Layout, Menu } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import blackarrow from "../images/blackarrow.png"
import whitearrow from "../images/arrow.png"
import { useWindowScrollPosition } from "rooks"
import "../styles/header.scss"
import MenuNew from "./MenuNew"

const Header = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const[scrollPosition,setScrollPosition]=useState(0)
  const [menu, setMenu] = useState(false)

  const position = useWindowScrollPosition()
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
  useEffect(()=>{
    if(typeof window !=="undefined"){
      window.addEventListener("load",handleScroll, { passive: true })
      window.addEventListener("scroll",handleScroll, { passive: true })
      return ()=>{
        window.removeEventListener("load",handleScroll)
        window.removeEventListener("scroll",handleScroll)
      }
    }
  },[scrollPosition])
  let LifeAVM
  if (typeof window !== "undefined") {
    LifeAVM = window.location.pathname.includes("/lifeAtVisiomate")
  }
  let isServiceOdoPage
  if (typeof window !== "undefined") {
    isServiceOdoPage = window.location.pathname.includes("/Odoo")
  }
  let products
  if (typeof window !== "undefined") {
    products = window.location.pathname.includes("/products")
  }
  let isAboutHeader
  let news
  let blogDetails
  let blogDetails2
  let Blogs
  let WebinarEvents
  let WhitepapereBooks
  if (typeof window !== "undefined") {
    isAboutHeader = window.location.pathname.includes("/about")
    news = window.location.pathname.includes("/NEWS")
    // Blogs = window.location.pathname.includes("/Blogs")
    // blogDetails = window.location.pathname.includes("/BlogsDetails")
    // blogDetails2 = window.location.pathname.includes("/BlogsDetails2")
    // WebinarEvents = window.location.pathname.includes("/WebinarEvents")
    WhitepapereBooks = window.location.pathname.includes("/WhitepapereBooks")
  }
  let hideLink
  if (typeof window !== "undefined") {
    hideLink = window.location.pathname === "/"
  }
  let hideLetTalkLink
  if (typeof window !== "undefined") {
    hideLetTalkLink = window.location.pathname === "/contact"
  }

  const MenuClick = () => {
    setMenu(!menu)
  }
  const onClose = () => {
    setMenu(!menu)
  }
  const queryData = useStaticQuery(graphql`
    query HeaderQuery {
      VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UMTVisiomate: file(
        relativePath: { eq: "lifeatvisiomate/UMT_Visiomate3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      umrahshuttleapp: file(
        relativePath: { eq: "mobile/umrahshuttleapp.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      viztosForMenu: file(relativePath: { eq: "news/viztos.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      learningcircle: file(relativePath: { eq: "news/learning_circle.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      CloseIcon: file(relativePath: { eq: "Rectangle86.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter_bird.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youtube: file(relativePath: { eq: "youtube.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Layout.Header
        className={scrollPosition>=40? "header-responsive" : "header-responsive2"}
      >
        <div className="logo-div">
          <Link to="/" className="logo">
            {scrollPosition>=40 ? (
              <Img
                fluid={queryData.VMLogoBlack.childImageSharp.fluid}
                alt="VMLogoBlack"
              />
            ) : (
              <div></div>
            )}
          </Link>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[isBrowser ? window.location.pathname : "/"]}
          style={{
            borderBottom: "none",
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={scrollPosition>=40 ? "with-bg" : ""}
        >
          {(hideLink && !hideLetTalkLink) ||
            (!hideLink && hideLetTalkLink) ||
            isAboutHeader ||
            LifeAVM ? (
            <>
              {!hideLink && (
                <Link
                  to="/"
                  style={{
                    color:
                      position?.scrollY >= 40 || isAboutHeader
                        ? ""
                        : "#fff",
                    marginRight: "1.25em",
                    cursor: "pointer",
                  }}
                >
                  Home
                </Link>
              )}
              {!hideLetTalkLink && (
                <Link to="/contact">
                  {scrollPosition>=600 ? (
                    <button className="letConnectButton">
                      Let's Connect
                      <img src={blackarrow} alt="connect arrow"/>
                    </button>
                  ) : (
                    <div></div>
                  )}
                </Link>
              )}
            </>
          ) : (
            <>
              <>
                {!hideLink && (
                  <Link
                    to="/"
                    style={{
                      color:
                        scrollPosition>=40 ||
                          isServiceOdoPage ||
                          products ||
                          isAboutHeader ||
                          news ||
                          blogDetails ||
                          blogDetails2 ||
                          Blogs ||
                          WebinarEvents ||
                          WhitepapereBooks
                          ? ""
                          : "#fff",

                      cursor: "pointer",
                    }}
                    className="home-link"
                  >
                    Home
                  </Link>
                )}
              </>
              <>
                {!hideLetTalkLink && (
                  <Link to="/contact"
                  style={{
                   
                        borderColor:scrollPosition>=40 ||
                        isServiceOdoPage ||
                        products ||
                        isAboutHeader ||
                        news ||
                        blogDetails ||
                        blogDetails2 ||
                        Blogs ||
                        WebinarEvents ||
                        WhitepapereBooks
                        ? "black"
                        : "#fff",
                    cursor: "pointer",
                  }}>
                    <button className="letConnectButton" style={{borderColor:scrollPosition>=40 ||
                        isServiceOdoPage ||
                        products ||
                        isAboutHeader ||
                        news ||
                        blogDetails ||
                        blogDetails2 ||
                        Blogs ||
                        WebinarEvents ||
                        WhitepapereBooks
                        ? "black"
                        : "#fff" ,color:
                        scrollPosition>=40 ||
                          isServiceOdoPage ||
                          products ||
                          isAboutHeader ||
                          news ||
                          blogDetails ||
                          blogDetails2 ||
                          Blogs ||
                          WebinarEvents ||
                          WhitepapereBooks
                          ? ""
                          : "#fff"
                        }}>
                      Let's Connect
                      <img src={scrollPosition>=40 ||
                        isServiceOdoPage ||
                        products ||
                        isAboutHeader ||
                        news ||
                        blogDetails ||
                        blogDetails2 ||
                        Blogs ||
                        WebinarEvents ||
                        WhitepapereBooks?blackarrow:whitearrow} alt="connect arrow"/>
                    </button>
                  </Link>
                )}
              </>
            </>
          )}
          <MenuOutlined
            style={{
              fontSize: "24px",
              color:
                scrollPosition>=40 ||
                  isServiceOdoPage ||
                  products ||
                  isAboutHeader ||
                  news ||
                  blogDetails ||
                  blogDetails2 ||
                  Blogs ||
                  WebinarEvents ||
                  WhitepapereBooks
                  ? "#0C1754"
                  : "#fff",
            }}
            onClick={MenuClick}
          />
        </Menu>
      </Layout.Header>

      <MenuNew isOpen={menu} onClose={onClose} queryData={queryData} />
    </>
  )
}

export default memo(Header)
